"use strict";

const $ = require("./main.js");

$(document).ready(function ($) {

    $('#input-article-search').autocomplete({
        autoFocus: true,
        source: function (request, response) {
            $.ajax({
                url: '/why-arvida/news-and-articles/search',
                dataType: 'json',
                data: {term: request.term},
                success: function (data) {
                    if (!data.length) {
                        response([{
                            noResultsFound: 1
                        }]);
                    } else {
                        response($.map(data, function (item) {
                            return item;
                        }));
                    }
                },
            });
        },
        minLength: 1,
        select: function (event, ui) {
            event.preventDefault();
            $(this).val('');
            if (typeof ui.item.noResultsFound === 'undefined') {
                var protocol = location.protocol;
                var slashes = protocol.concat('//');
                var host = slashes.concat(window.location.hostname);
                window.location.href = '/why-arvida/news-and-articles/' + ui.item._slug;
            }
        },
    }).data('ui-autocomplete')._renderItem = function (ul, item) {
        var html = null;
        if (typeof item.noResultsFound !== 'undefined') {
            html = `<li class="autocomplete-search-option autocomplete-search-option--no-results">
                                Sorry, we can't find anything with your search query.
                            </li>`;
        } else {
            html = `<div>${String(item.title).replace(new RegExp(this.term, 'gi'), '<span class="autocomplete-highlight">$&</span>')}</div>`;
        }

        return $("<li></li>")
            .append(html)
            .appendTo(ul);
    };

});
